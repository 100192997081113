import React, { useEffect, useState } from "react";
import { Form, Input, Button, Checkbox, Radio, message } from "antd";
import api from "../../api";
import { useHistory } from "react-router";
import Editor from 'ckeditor5-custom-build/build/ckeditor';
import { CKEditor } from '@ckeditor/ckeditor5-react'
const { TextArea } = Input;

const AddTeam = (props) => {
  const [jobTitle, setJobTitle] = useState("");
  const [location, setLocation] = useState("");
  const [shortDesc, setShortDesc] = useState("");
  const [time, setTime] = useState('');
  const [longDesc, setLongDesc] = useState("");
  const [doing, setDoing] = useState("");
  const [skill, setSkills] = useState("");
  const [apply, setApply] = useState("");
  const [url, setUrl] = useState("");
  const [status, setStatus] = useState("")
  const [experience, setExperience] = useState('')
  const history = useHistory();
  const token = JSON.parse(localStorage.getItem("token"));

  useEffect(() => {
    if (history.location.state && history.location.state.status) {
      let data = history.location.state.status;
      setJobTitle(data.job_title)
      setTime(data.part_full)
      setLongDesc(data.long_description)
      setDoing(data.what_you_doing)
      setSkills(data.skills_qualifications)
      setApply(data.how_to_apply)
      setUrl(data.apply_now_url)
      setLocation(data.location)
      setShortDesc(data.short_description)
      setStatus(data.status)
      setExperience(data.experience)
    }
  }, [])

  const onRadioChange = e => {
    setStatus(e.target.value);
  };

  const onTimeChange = e => {
    setTime(e.target.value);
  };

  const handleLongDesc = (event, editor) => {
    const data = editor.getData();
    setLongDesc(data);
  };

  const handleDoing = (event, editor) => {
    const data = editor.getData();
    setDoing(data);
  };

  const handleSkillChange = (event, editor) => {
    const data = editor.getData();
    setSkills(data);
  };

  const handleApplyChange = (event, editor) => {
    const data = editor.getData();
    setApply(data);
  };

  const addOrEditCareer = () => {
    if (!jobTitle || !shortDesc || !location || !time || !longDesc || !doing || !skill || !apply || !url || !status) {
      alert("Please enter all required entries")
     return;
    }
      if (history.location.state && history.location.state.status) {
        let data = history.location.state.status;

        let updatedData = {
          careers_id: data.careers_id,
          job_title: jobTitle,
          experience: experience,
          location: location,
          short_description: shortDesc,
          status: status,
          part_full: time,
          long_description: longDesc,
          what_you_doing: doing,
          skills_qualifications: skill,
          how_to_apply: apply,
          apply_now_url: url,

        }
        api.careerCrud.updateCareer(updatedData, token).
          then(result => {
            if (result.status === 1) {
              message.success(result.message);
              setTimeout(() => {
                setJobTitle("")
                setTime("")
                setLongDesc("")
                setDoing("")
                setSkills("")
                setApply("")
                setUrl("")
                setLocation("")
                setShortDesc("")
                setStatus("")
                setExperience("")

                history.push('/career')
              }, 2000);
            } else {
              message.error(result.message);
            }
          }).catch(error => {
            console.log(error);
          })

      } else {
        let data = {
          job_title: jobTitle,
          experience: experience,
          location: location,
          short_description: shortDesc,
          status: status,
          part_full: time,
          long_description: longDesc,
          what_you_doing: doing,
          skills_qualifications: skill,
          how_to_apply: apply,
          apply_now_url: url,

        }
        api.careerCrud.addCareer(data, token).
          then(result => {
            if (result.status === 1) {
              message.success(result.message);
              setTimeout(() => {
                setJobTitle("")
                setTime("")
                setLongDesc("")
                setDoing("")
                setSkills("")
                setApply("")
                setUrl("")
                setLocation("")
                setShortDesc("")
                setStatus("")
                setExperience("")
                history.push('/career')
              }, 3200);
            } else {
              message.error(result.message);
            }
          }).catch(error => {
            console.log(error)
          })
      }
    
  }

  return (
    <div>
      <h2>{history.location.state ? "Update Open Position" : "Add Open Position"}</h2>
      <Form
        style={{ marginTop: "20px", marginLeft: "50px" }}
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 10 }}
        size={"default"}
        labelAlign={"left"}
      >
        <Form.Item
          label="Job Title"
          required={true}
        >
          <Input value={jobTitle}  onChange={(e) => setJobTitle(e.target.value)} />
        </Form.Item>
        <Form.Item
          label="Short Description"
          required={true}
        >
          <TextArea rows={4} value={shortDesc} maxLength="340" onChange={(e) => setShortDesc(e.target.value)} />
        </Form.Item>
        <div className="" style={{ display: 'flex', justifyContent: 'end', width: '58%', marginTop: '-18px' }}>
          {shortDesc.length}/340
        </div>
        <Form.Item
          label="Location"
          required={true}
        >
          <Input value={location} onChange={(e) => setLocation(e.target.value)} />
        </Form.Item>
        <Form.Item
          label="Part Time/Full Time"
          required={true}
        >
          <Radio.Group
            value={time} onChange={onTimeChange}>
            <Radio value={"Part Time"}>
              Part Time
            </Radio>
            <Radio value={"Full Time"}>
              Full Time
            </Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          label="Long Description"
          required={true}
        >
          <CKEditor
            editor={Editor}
            onReady={(editor) => { editor.setData(longDesc);
            editor.editing.view.change((writer) => {
               writer.setStyle(
            "height",
            "250px",
            editor.editing.view.document.getRoot()
        );
        });
            }}
            onChange={handleLongDesc}
          />
        </Form.Item>
        <Form.Item
          label="What you'll be doing"
          required={true}
        >
          <CKEditor
             editor={Editor}
            onReady={(editor) => { editor.setData(doing);
            editor.editing.view.change((writer) => {
               writer.setStyle(
            "height",
            "250px",
            editor.editing.view.document.getRoot()
        );
        });
            }}
            onChange={handleDoing}
           

          />
        </Form.Item>
        <Form.Item
          label="Skills and Qualification"
          required={true}
        >
          <CKEditor
             editor={Editor}
            onReady={(editor) => { editor.setData(skill);
            editor.editing.view.change((writer) => {
               writer.setStyle(
            "height",
            "250px",
            editor.editing.view.document.getRoot()
        );
        });
            }}
            onChange={handleSkillChange}
          />
        </Form.Item>
        <Form.Item
          label="How to Apply"
          required={true}
        >
          <CKEditor
            editor={Editor}
            onReady={(editor) => { editor.setData(apply);
            editor.editing.view.change((writer) => {
               writer.setStyle(
            "height",
            "250px",
            editor.editing.view.document.getRoot()
        );
        });
            }}
            onChange={(handleApplyChange)}
            data={apply}

          />
        </Form.Item>
        <Form.Item
          label="Apply Now URL"
          required={true}
        >
          <Input value={url} onChange={(e) => setUrl(e.target.value)} />
        </Form.Item>

        <Form.Item
          label="Status"
          required={true}
        >
          <Radio.Group
            value={status} onChange={onRadioChange}>
            <Radio value={"Active"}>
              Active
            </Radio>
            <Radio value={"Inactive"}>
              Inactive
            </Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" onClick={() => {
            addOrEditCareer()
          }}>{history.location.state ? "Update Position" : "Add Position"}</Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default AddTeam;
