import React, { useState, useEffect, useRef } from "react";
import {
  Form,
  Input,
  Button,
  DatePicker,
  Checkbox,
  Radio,
  Upload,
  message,
  Result,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";
import api from "../../api";
import { useHistory } from "react-router";
import moment from "moment";
import FormData from "form-data";
import { Editor } from "@tinymce/tinymce-react";
import "../../style.css";
import { API_BASE_URL } from "../../app_constants";
const { TextArea } = Input;

const AddBlog = () => {
  const [blog, setBlog] = useState("");
  const [shortDesc, setShortDesc] = useState("");
  const [longDesc, setLongDesc] = useState("");
  const [date, setDate] = useState("");
  const [autherName, setAutherName] = useState("");
  const [meta, setMeta] = useState("");
  const [status, setStatus] = useState("");
  const [file, setFile] = useState("");
  const [imgs, setImg] = useState("");
  const [sequence, setSequence] = useState();

  const history = useHistory();
  const token = JSON.parse(localStorage.getItem("token"));

  useEffect(() => {
    editData();
  }, []);

  const editData = async () => {
    if (history.location.state && history.location.state.status) {
      let data = history.location.state.status;

      setBlog(data.blog_title);
      setShortDesc(data.short_description);
      await setLongDesc(data.long_description);
      setDate(moment(data.date));
      setAutherName(data.author_name);
      setMeta(data.meta);
      setStatus(data.status);
      setImg(data.blog_image);
      setFile(data.blog_image);
      setSequence(data.sequence);
    }
  };
  const addOrEditBlog = () => {
    if (
      !blog ||
      !shortDesc ||
      !longDesc ||
      !date ||
      !autherName ||
      !meta ||
      !file ||
      !status
    ) {
      alert("Please enter all required entries");
      return;
    }
    if (history.location.state && history.location.state.status) {
      let data = history.location.state.status;

      let updatedData = new FormData();
      updatedData.append("blogs_id", data.blogs_id);
      updatedData.append("blog_title", blog);
      updatedData.append("short_description", shortDesc);
      updatedData.append("long_description", longDesc);
      updatedData.append("date", date);
      updatedData.append("author_name", autherName);
      updatedData.append("meta", meta);
      updatedData.append("status", status);
      updatedData.append("blog_image", file);
      updatedData.append("sequence", sequence);

      api.blogCrud
        .updateBlog(updatedData, token)
        .then((result) => {
          if (result.status === 1) {
            message.success(result.message);
            setTimeout(() => {
              setBlog("");
              setShortDesc("");
              setLongDesc("");
              setDate("");
              setAutherName("");
              setMeta("");
              setStatus("");
              setSequence();
              history.push("/blogs");
            }, 2000);
          } else {
            message.success(result.message);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      let data = new FormData();
      data.append("blog_title", blog);
      data.append("short_description", shortDesc);
      data.append("long_description", longDesc);
      data.append("date", date);
      data.append("author_name", autherName);
      data.append("meta", meta);
      data.append("status", status);
      data.append("blog_image", file);
      data.append("sequence", sequence);
      api.blogCrud
        .addBlog(data, token)
        .then((result) => {
          if (result.status === 1) {
            message.success(result.message);
            setTimeout(() => {
              setBlog("");
              setShortDesc("");
              setLongDesc("");
              setDate("");
              setAutherName("");
              setMeta("");
              setStatus("");
              setSequence();
              history.push("/blogs");
            }, 2000);
          } else {
            message.error(result.message);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const editorRef = useRef(null);

  return (
    <div>
      <h2 style={{ marginLeft: "4%" }}>
        {history.location.state ? "Update Blog" : "Add Blog"}
      </h2>
      <Form
        style={{ marginTop: "20px", marginLeft: "50px" }}
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 12 }}
        size={"default"}
        labelAlign={"left"}
      >
        <Form.Item label="Blog Title" required={true}>
          <Input value={blog} onChange={(e) => setBlog(e.target.value)} />
        </Form.Item>
        <Form.Item label="Short Description" required={true}>
          <TextArea
            value={shortDesc}
            rows={4}
            maxLength="340"
            onChange={(e) => setShortDesc(e.target.value)}
          />
        </Form.Item>
        <span
          className=""
          style={{
            display: "flex",
            justifyContent: "end",
            width: "65%",
            marginTop: "-18px",
          }}
        >
          {shortDesc.length}/340
        </span>
        <Form.Item label="Long Description" required={true}>
          <Editor
            onInit={(evt, editor) => (editorRef.current = editor)}
            value={longDesc}
            init={{
              height: 500,
              selector: "textarea",
              a11y_advanced_options: true,
              automatic_uploads: true,
              imagetools_cors_hosts: [`${API_BASE_URL}/admin/uploadimage`],
              images_upload_url: `${API_BASE_URL}/admin/uploadimage`,
              block_unsupported_drop: false,
              paste_data_images: true,
              menubar: true,
              plugins: [
                "advlist autolink lists link image charmap print preview anchor",
                "searchreplace visualblocks code fullscreen",
                "insertdatetime media table paste code help wordcount image imagetools",
              ],
              toolbar:
                "undo redo | formatselect | " +
                "image | paste |" +
                "bold italic underline forecolor | backcolor  fontsizeselect fontselect | alignleft aligncenter " +
                "alignright alignjustify | bullist numlist outdent indent | " +
                "removeformat | help | subscript superscript",
              content_style: "@import url('./fonts/flink-regular.otf');",
              font_formats:
                "Flink Medium=flink medium;Flink Regular=Flink Regular;",
            }}
            onEditorChange={(value) => setLongDesc(value)}
          />
        </Form.Item>
        <Form.Item label="Date" required={true}>
          <DatePicker
            value={date}
            onChange={(value) => setDate(value)}
            format={"DD-MM-YYYY"}
          />
        </Form.Item>
        <Form.Item label="Author Name" required={true}>
          <Input
            value={autherName}
            onChange={(e) => setAutherName(e.target.value)}
          />
        </Form.Item>
        <Form.Item label="Meta" required={true}>
          <TextArea
            value={meta}
            rows={2}
            onChange={(e) => setMeta(e.target.value)}
          />
        </Form.Item>
        <Form.Item
          label="Image"
          required={true}
          help="*Please upload image of size 246px x 272px"
        >
          <Input
            type="file"
            onChange={(e) => {
              setImg(
                URL.createObjectURL(e.target.files[0]),
                setFile(e.target.files[0])
              );
            }}
          />
          {imgs ? <img src={imgs} height="90px" width="90px" /> : ""}
        </Form.Item>
        <Form.Item label="Sequence">
          <Input
            type="number"
            min={1}
            value={sequence}
            onChange={(e) => setSequence(e.target.value)}
          />
        </Form.Item>
        <Form.Item label="Status" required={true}>
          <Radio.Group
            value={status}
            onChange={(e) => {
              setStatus(e.target.value);
            }}
          >
            <Radio value={"Active"}>Active</Radio>
            <Radio value={"Inactive"}>Inactive</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item>
          <Button
            htmlType="submit"
            type="primary"
            onClick={() => {
              addOrEditBlog();
            }}
            style={{ marginLeft: "34%" }}
          >
            {history.location.state ? "Update Blog" : "Add Blog"}
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default AddBlog;
