import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { Table, Button, Popconfirm, message, Modal } from "antd";
import { DeleteOutlined, EyeOutlined } from "@ant-design/icons";
import api from "../../api";

const Enquiry = () => {
  const [enquiryData, setEnquiryData] = useState([]);
  const [action, setAction] = useState("");
  const [enquiryRecord, setEnquiryRecord] = useState({});
  const [isVisible, setIsVisible] = useState(false);
  const [loading, setLoading] = useState(true);
  const history = useHistory();
  const token = JSON.parse(localStorage.getItem("token"));

  const columns = [
    {
      title: "Full Name",
      key: "full_name",
      dataIndex: "full_name",
    },
    {
      title: "Email",
      key: "email",
      dataIndex: "email",
    },
    {
      title: "Phone No.",
      key: "mobile_no",
      dataIndex: "mobile_no",
    },
    {
      title: "Message",
      key: "description",
      dataIndex: "description",
      ellipsis: true,
    },
    {
      title: "Actions",
      key: "action",
      dataIndex: "action",
      render: () => {
        return (
          <div>
            <Button
              icon={<EyeOutlined />}
              type="primary"
              onClick={() => setIsVisible(true)}
            ></Button>
            <Popconfirm
              title="Are you sure to delete this Enquiry?"
              onConfirm={deleteEnquiry}
              okText="Yes"
              cancelText="No"
            >
              <Button
                icon={<DeleteOutlined />}
                type="primary"
                danger
                style={{ marginLeft: "10px" }}
              ></Button>
            </Popconfirm>
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    if (action === "EDIT") {
      setTimeout(() => {
        history.push(
          {
            pathname: "/addenquiry",
            state: {
              status: enquiryRecord,
            },
          },
          2000
        );
      });
    }
  }, [enquiryRecord, action]);

  useEffect(() => {
    getEnquiryList();
  }, []);

  const deleteEnquiry = () => {
    let data = {
      enquiries_id: enquiryRecord.enquiries_id,
    };
    api.enquiryCrud
      .deleteEnquiry(data, token)
      .then((result) => {
        if (result.status === 1) {
          message.success(result.message);
          getEnquiryList();
        } else {
          message.error(result.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getEnquiryList = () => {
    api.enquiryCrud
      .getEnquiry(token)
      .then((result) => {
        if (result.status === 1) {
          setEnquiryData(result.data.reverse());
          setLoading(false);
        } else {
          setEnquiryData([]);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div>
      <div>
        <h2 style={{ display: "inline-block" }}>Contact Us Requests</h2>
      </div>
      <Table
        columns={columns}
        dataSource={enquiryData}
        loading={loading}
        onRow={(record) => ({
          onClick: () => {
            setEnquiryRecord(record);
          },
        })}
      />
      <Modal
        title="Enquiry Information"
        visible={isVisible}
        onOk={() => setIsVisible(false)}
        onCancel={() => setIsVisible(false)}
      >
        <>
          <b>Name</b> - {enquiryRecord.full_name}
          <br />
          <b>Email Address</b> - {enquiryRecord.email}
          <br />
          <b>Phone</b> - {enquiryRecord.mobile_no} <br />
          <b>Subject</b> - {enquiryRecord.subject}
          <br />
          <b>Message</b> - {enquiryRecord.description}
        </>
      </Modal>
    </div>
  );
};

export default Enquiry;
