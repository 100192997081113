import { Result } from "antd";
import axios from "axios";
import { API_BASE_URL } from "./app_constants.js";


export default {
  //  ==================================== LOGIN =================================
  auth: {
    login: loginData =>
      axios.post(API_BASE_URL + "/admin/login", loginData,
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      ).then(res => {
        console.log(" api loginData ==========>>> res:", res);
        return res.data;
      }),
      logout: token =>
      axios.post(API_BASE_URL + `/admin/logout?token=${token}`, {},
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      ).then(res => {
        console.log(" api logoutData ==========>>> res:", res);
        return res.data;
      }),
  },

  password: {
    changePassword: (data,token) =>
    axios.post(API_BASE_URL + `/admin/change_password?token=${token}`,data,{
       headers: {
            "Content-Type": "application/json",
          }
        }
     ).then(res => {
       console.log("Password Changes ====>",res)
       return res.data
     })
  },
  //  ==================================== CAREER =================================
  careerCrud: {
    addCareer: (data, token) =>
      axios.post(API_BASE_URL + `/admin/careers/add?token=${token}`, data,
        {
          headers: {
            "Content-Type": "application/json",
          }
        }
      ).then(res => {
        console.log("API ADD CARRER=====> res", res);
        return res.data;
      }),

    getCareer: async (token) =>
      await axios.get(API_BASE_URL + `/admin/careers/get?token=${token}`, {
        headers: {
          "Content-Type": "application/json",
        }
      }).then(res => {
        console.log("API GET CARRER=====> res", res);
        return res.data;
      }),

    deleteCareer: (data, token) =>
      axios.post(API_BASE_URL + `/admin/careers/delete?token=${token}`, data, {
        headers: {
          "Content-Type": "application/json",
        }
      }).then(res => {
        console.log("API DEL CARRER=====> res", res);
        return res.data;
      }),

    updateCareer: (data, token) =>
      axios.post(API_BASE_URL + `/admin/careers/update?token=${token}`, data, {
        headers: {
          "Content-Type": "application/json",
        }
      }).then(res => {
        console.log("API UPDATE CARRER=====> res", res);
        return res.data;
      }),
  },
  //  ==================================== TEAM MEMBER =================================
  team_member_Crud: {
    addTeamMember: (data, token) =>
      axios.post(API_BASE_URL + `/admin/team_members/add?token=${token}`, data,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          }
        }
      ).then(res => {
        console.log("API ADD TEAM MEMBER=====> res", res);
        return res.data;
      }),

    getTeamMember: async (token) =>
      await axios.get(API_BASE_URL + `/admin/team_members/get?token=${token}`, {
        headers: {
          "Content-Type": "application/json",
        }
      }).then(res => {
        console.log("API GET TEAM MEMBER=====> res", res);
        return res.data;
      }),

    deleteTeamMember: (data, token) =>
      axios.post(API_BASE_URL + `/admin/team_members/delete?token=${token}`, data, {
        headers: {
          "Content-Type": "application/json",
        }
      }).then(res => {
        console.log("API DEL TEAM MEMBER=====> res", res);
        return res.data;
      }),

    updateTeamMember: (data, token) =>
      axios.post(API_BASE_URL + `/admin/team_members/update?token=${token}`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        }
      }).then(res => {
        console.log("API UPDATE TEAM MEMBER=====> res", res);
        return res.data;
      }),
  },
  //  ==================================== BLOG =================================
  blogCrud: {
    addBlog: (data, token) =>
      axios.post(API_BASE_URL + `/admin/blogs/add?token=${token}`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        }
      }).then(res => {
        console.log("API ADD BLOG=====> res", res);
        return res.data;
      }),
    
      addFeature: (data, token) =>
      axios.post(API_BASE_URL + `/admin/blogs/make_featured?token=${token}`, data, {
        headers: {
          "Content-Type": "application/json",
        }
      }).then(res => {
        console.log("API ADD Feature=====> res", res);
        return res.data;
      }),

    getBlog: async (token) =>
      await axios.get(API_BASE_URL + `/admin/blogs/get?token=${token}`, {
        headers: {
          "Content-Type": "application/json",
        }
      }).then(res => {
        console.log("API GET BLOG=====> res", res);
        return res.data;
      }),

    deleteBlog: (data, token) =>
      axios.post(API_BASE_URL + `/admin/blogs/delete?token=${token}`, data, {
        headers: {
          "Content-Type": "application/json",
        }
      }).then(res => {
        console.log("API DEL BLOG=====> res", res);
        return res.data;
      }),

    updateBlog: (data, token) =>
      axios.post(API_BASE_URL + `/admin/blogs/update?token=${token}`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        }
      }).then(res => {
        console.log("API UPDATE BLOG=====> res", res);
        return res.data;
      }),
  },
  //  ==================================== ENQUIRY =================================
  enquiryCrud: {
    addEnquiry: (data, token) =>
      axios.post(API_BASE_URL + `/admin/contact_us/add?token=${token}`, data, {
        headers: {
          "Content-Type": "application/json",
        }
      }).then(res => {
        console.log("API ADD ENQUIRY=====> res", res);
        return res.data;
      }),

    getEnquiry: async (token) =>
      await axios.get(API_BASE_URL + `/admin/contact_us/get?token=${token}`, {
        headers: {
          "Content-Type": "application/json",
        }
      }).then(res => {
        console.log("API GET ENQUIRY=====> res", res);
        return res.data;
      }),

    deleteEnquiry: (data, token) =>
      axios.post(API_BASE_URL + `/admin/contact_us/delete?token=${token}`, data, {
        headers: {
          "Content-Type": "application/json",
        }
      }).then(res => {
        console.log("API DEL ENQUIRY=====> res", res);
        return res.data;
      }),

    updateEnquiry: (data, token) =>
      axios.post(API_BASE_URL + `/admin/contact_us/update?token=${token}`, data, {
        headers: {
          "Content-Type": "application/json",
        }
      }).then(res => {
        console.log("API UPDATE ENQUIRY=====> res", res);
        return res.data;
      }),
  },
  //  ==================================== RESUME =================================
  resumeCrud: {
    addResume: (data, token) =>
      axios.post(API_BASE_URL + `/admin/resumes/add?token=${token}`, data, {
        headers: {
          "Content-Type": "application/json",
        }
      }).then(res => {
        console.log("API ADD RESUME=====> res", res);
        return res.data;
      }),

    getResume: async (token) =>
      await axios.get(API_BASE_URL + `/admin/resumes/get?token=${token}`, {
        headers: {
          "Content-Type": "application/json",
        }
      }).then(res => {
        console.log("API GET RESUME=====> res", res);
        return res.data;
      }),

    deleteResume: (data, token) =>
      axios.post(API_BASE_URL + `/admin/resumes/delete?token=${token}`, data, {
        headers: {
          "Content-Type": "application/json",
        }
      }).then(res => {
        console.log("API DEL RESUME=====> res", res);
        return res.data;
      }),

    updateResume: (data, token) =>
      axios.post(API_BASE_URL + `/admin/resumes/update?token=${token}`, data, {
        headers: {
          "Content-Type": "application/json",
        }
      }).then(res => {
        console.log("API UPDATE RESUME=====> res", res);
        return res.data;
      }),
  },
  //  ==================================== CSR =================================
  csrCrud: {
    addCsr: (data, token) =>
      axios.post(API_BASE_URL + `/admin/csr/add?token=${token}`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        }
      }).then(res => {
        console.log("API ADD CSR=====> res", res);
        return res.data;
      }),

    getCsr: (token) =>
      axios.get(API_BASE_URL + `/admin/csr/get?token=${token}`, {
        headers: {
          "Content-Type": "multipart/form-data",
        }
      }).then(res => {
        console.log("API GET CSR=====> res", res);
        return res.data;
      }),

    deleteCsr: (data, token) =>
      axios.post(API_BASE_URL + `/admin/csr/delete?token=${token}`, data, {
        headers: {
          "Content-Type": "application/json",
        }
      }).then(res => {
        console.log("API DEL CSR=====> res", res);
        return res.data;
      }),

    updateCsr: (data, token) =>
      axios.post(API_BASE_URL + `/admin/csr/update?token=${token}`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        }
      }).then(res => {
        console.log("API UPDATE CSR=====> res", res);
        return res.data;
      }),

  },
  //  ==================================== EVENT =================================
  eventCrud: {
    addEvent: (data, token) =>
      axios.post(API_BASE_URL + `/admin/events/add?token=${token}`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        }
      }).then(res => {
        console.log("API ADD event=====> res", res);
        return res.data;
      }),

    getEvent: (token) =>
      axios.get(API_BASE_URL + `/admin/events/get?token=${token}`, {
        headers: {
          "Content-Type": "multipart/form-data",
        }
      }).then(res => {
        console.log("API GET event=====> res", res);
        return res.data;
      }),

    deleteEvent: (data, token) =>
      axios.post(API_BASE_URL + `/admin/events/delete?token=${token}`, data, {
        headers: {
          "Content-Type": "application/json",
        }
      }).then(res => {
        console.log("API DEL event=====> res", res);
        return res.data;
      }),

    updateEvent: (data, token) =>
      axios.post(API_BASE_URL + `/admin/events/update?token=${token}`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        }
      }).then(res => {
        console.log("API UPDATE event=====> res", res);
        return res.data;
      }),

  },
}
