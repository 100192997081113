import React, { useEffect, useState } from "react";
import { Table, Button, message, Popconfirm } from "antd";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import api from "../../api";
import { useHistory } from "react-router";
import moment from "moment";

const Blogs = () => {
  const [blogData, setblogData] = useState([]);
  const [action, setAction] = useState("");
  const [blogRecord, setBlogRecord] = useState({});
  const [loading, setLoading] = useState(true);
  const history = useHistory();
  const token = JSON.parse(localStorage.getItem("token"));

  useEffect(() => {
    getBlogList();
  }, []);

  useEffect(() => {
    if (action === "EDIT") {
      setTimeout(() => {
        history.push(
          {
            pathname: "/addblog",
            state: {
              status: blogRecord,
            },
          },
          2000
        );
      });
    }
  }, [blogRecord, action]);

  const getBlogList = () => {
    api.blogCrud
      .getBlog(token)
      .then((result) => {
        if (result.status === 1) {
          const newarr = result.data.sort((a, b) => {
            let dateA = new Date(
              moment(a.date).format("YYYY-MM-DD").toString()
            );
            let dateB = new Date(
              moment(b.date).format("YYYY-MM-DD").toString()
            );
            return dateB - dateA;
          });
          setblogData(newarr);
          setLoading(false);
        } else {
          setLoading(false);
          setblogData([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const delteBlog = () => {
    let data = { blogs_id: blogRecord.blogs_id };
    api.blogCrud
      .deleteBlog(data, token)
      .then((result) => {
        if (result.status === 1) {
          message.success(result.message);
          getBlogList();
        } else {
          message.error(result.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleMakeFeature = () => {
    let data = { blogs_id: blogRecord.blogs_id };
    api.blogCrud
      .addFeature(data, token)
      .then((result) => {
        if (result.status === 1) {
          message.success(result.message);
          getBlogList();
        } else {
          message.error(result.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const columns = [
    {
      title: "Blog Title",
      key: "blog_title",
      dataIndex: "blog_title",
    },
    {
      title: "Short Description",
      key: "short_description",
      dataIndex: "short_description",
      ellipsis: true,
    },
    {
      title: "Date",
      key: "date",
      dataIndex: "date",
      render: (data) => {
        return moment(data).format("DD-MM-YYYY");
      },
    },
    {
      title: "Author Name",
      key: "author_name",
      dataIndex: "author_name",
    },
    {
      title: "Meta",
      key: "meta",
      dataIndex: "meta",
    },
    {
      title: "Sequence",
      key: "sequence",
      dataIndex: "sequence",
    },
    {
      title: "Status",
      key: "status",
      dataIndex: "status",
    },
    {
      title: "Actions",
      key: "action",
      dataIndex: "action",
      render: () => {
        return (
          <div>
            <Button
              icon={<EditOutlined />}
              type="primary"
              onClick={() => {
                setAction("EDIT");
              }}
            ></Button>

            <Popconfirm
              title="Are you sure to delete this blog?"
              onConfirm={delteBlog}
              okText="Yes"
              cancelText="No"
            >
              <Button
                icon={<DeleteOutlined />}
                type="primary"
                danger
                style={{ marginLeft: "10px" }}
              ></Button>
            </Popconfirm>
            <Popconfirm
              title="Are you sure to make featured this blog?"
              onConfirm={handleMakeFeature}
              okText="Yes"
              cancelText="No"
            >
              <Button
                type="primary"
                style={{
                  marginRight: "20px",
                  marginTop: "10px",
                  marginLeft: "-20px",
                }}
              >
                Make Featured{" "}
              </Button>
            </Popconfirm>
          </div>
        );
      },
    },
    {
      title: "Is Featured",
      key: "is_featured",
      dataIndex: "is_featured",
    },
  ];

  return (
    <div>
      <div>
        <h2 style={{ display: "inline-block" }}>Blogs</h2>
        <Link to="/addblog">
          <Button type="primary" style={{ float: "right", top: "50%" }}>
            Add Blog Post
          </Button>
        </Link>
      </div>
      <Table
        columns={columns}
        dataSource={blogData}
        loading={loading}
        onRow={(record) => ({
          onClick: () => {
            setBlogRecord(record);
          },
        })}
      />
    </div>
  );
};

export default Blogs;
