import React, { useEffect, useState } from "react";
import { Form, Input, Button, message } from "antd";
import api from "../../api";
import { useHistory } from "react-router";

const { TextArea } = Input;

const AddEnquiry = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [desc, setDesc] = useState("");

  const history = useHistory();
  const token = JSON.parse(localStorage.getItem("token"));

  useEffect(() => {
    if (history.location.state && history.location.state.status) {
      let data = history.location.state.status;
      setName(data.full_name);
      setEmail(data.email);
      setMobile(data.mobile_no);
      setDesc(data.description);
    }
  }, []);

  const addOrEditEnquiry = () => {
    if (history.location.state && history.location.state.status) {
      let data = history.location.state.status;
      let updatedData = {
        enquiries_id: data.enquiries_id,
        full_name: name,
        email: email,
        mobile_no: mobile,
        description: desc,
      };
      api.enquiryCrud
        .updateEnquiry(updatedData, token)
        .then((result) => {
          if (result.status === 1) {
            message.success(result.message);
            setTimeout(() => {
              setName("");
              setEmail("");
              setMobile("");
              setDesc("");

              history.push("/contact");
            }, 2000);
          } else {
            message.success(result.message);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      let data = {
        full_name: name,
        email: email,
        mobile_no: mobile,
        description: desc,
      };
      api.enquiryCrud
        .addEnquiry(data, token)
        .then((result) => {
          if (result.status === 1) {
            message.success(result.message);
            setTimeout(() => {
              setName("");
              setEmail("");
              setMobile("");
              setDesc("");

              history.push("/contact");
            }, 2000);
          } else {
            message.error(result.message);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  return (
    <div>
      <h2 style={{ marginLeft: "4%" }}>Add Enquiry</h2>
      <Form
        style={{ marginTop: "20px", marginLeft: "50px" }}
        labelCol={{ span: 3 }}
        wrapperCol={{ span: 10 }}
        size={"default"}
        labelAlign={"left"}
      >
        <Form.Item label="Full Name">
          <Input value={name} onChange={(e) => setName(e.target.value)} />
        </Form.Item>
        <Form.Item label="Email">
          <Input value={email} onChange={(e) => setEmail(e.target.value)} />
        </Form.Item>
        <Form.Item label="Mobile No.">
          <Input value={mobile} onChange={(e) => setMobile(e.target.value)} />
        </Form.Item>
        <Form.Item label="Description">
          <TextArea
            rows={3}
            value={desc}
            onChange={(e) => setDesc(e.target.value)}
          />
        </Form.Item>

        <Form.Item>
          <Button
            type="primary"
            style={{ marginLeft: "30%" }}
            onClick={addOrEditEnquiry}
          >
            {history.location.state ? "Update Member" : "Add Member"}
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default AddEnquiry;
