import React, { useEffect, useState } from "react";
import { Table, Button, Popconfirm, message } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import api from "../../api";

const Resume = () => {
  const [resumeData, setResumeData] = useState([]);
  const [resumeRecord, setresumeRecord] = useState({});
  const token = JSON.parse(localStorage.getItem("token"));
  useEffect(() => {
    getResumeList();
  }, []);

  const getResumeList = () => {
    api.resumeCrud
      .getResume(token)
      .then((result) => {
        console.log(result);
        if (result.status === 1) {
          setResumeData(result.data.reverse());
        } else {
          setResumeData([]);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const deleteResume = () => {
    let data = {
      resumes_id: resumeRecord.resumes_id,
    };
    api.resumeCrud
      .deleteResume(data, token)
      .then((result) => {
        if (result.status === 1) {
          message.success(result.message);
          getResumeList();
        } else {
          message.error(result.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const columns = [
    {
      title: "Name",
      key: "name",
      dataIndex: "name",
    },
    {
      title: "Email",
      key: "email",
      dataIndex: "email",
    },
    {
      title: "Mobile No.",
      key: "mobile_no",
      dataIndex: "mobile_no",
    },
    {
      title: "Resume",
      key: "resume_path",
      dataIndex: "resume_path",
      render: (urlDoc) => {
        console.log(urlDoc);
        return (
          <Button type="primary" href={urlDoc}>
            Download
          </Button>
        );
      },
    },
    {
      title: "Applied For",
      key: "applied_for",
      dataIndex: "applied_for",
    },
    {
      title: "Actions",
      key: "action",
      dataIndex: "action",
      render: () => {
        return (
          <div style={{ width: "100%" }}>
            <Popconfirm
              title="Are you sure to delete this Resume?"
              onConfirm={deleteResume}
              okText="Yes"
              cancelText="No"
            >
              <Button
                icon={<DeleteOutlined />}
                type="primary"
                danger
                style={{ marginLeft: "10px" }}
              ></Button>
            </Popconfirm>
          </div>
        );
      },
    },
  ];
  return (
    <div>
      <div>
        <h2 style={{ display: "inline-block" }}>Recieved Resume</h2>
      </div>
      <Table
        columns={columns}
        loading={resumeData.length > 0 ? false : true}
        dataSource={resumeData}
        onRow={(record) => ({
          onClick: () => {
            setresumeRecord(record);
          },
        })}
      />
    </div>
  );
};

export default Resume;
