import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { Table, Button, Popconfirm, message } from "antd";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import api from "../../api";

const Csr = () => {
  const [csrData, setCsrData] = useState([]);
  const [action, setAction] = useState("");
  const [csrRecord, setCsrRecord] = useState({});
  const [loading, setLoading] = useState(true);
  const history = useHistory();
  const token = JSON.parse(localStorage.getItem("token"));

  const columns = [
    {
      title: "Institute Name",
      key: "institute_name",
      dataIndex: "institute_name",
    },
    {
      title: "Event Name",
      key: "event_name",
      dataIndex: "event_name",
    },
    {
      title: "Event Description",
      key: "event_description",
      dataIndex: "event_description",
    },
    {
      title: "Status",
      key: "status",
      dataIndex: "status",
    },
    {
      title: "Image",
      key: "image_url",
      dataIndex: "image_url",
      render: (theImageURL) => {
        return (
          <div>
            <img
              alt={theImageURL}
              height="80px"
              width="80px"
              src={theImageURL}
            />
          </div>
        );
      },
    },
    {
      title: "Actions",
      key: "action",
      dataIndex: "action",
      render: () => {
        return (
          <div>
            <Button
              onClick={() => {
                setAction("EDIT");
              }}
              icon={<EditOutlined />}
              type="primary"
            ></Button>

            <Popconfirm
              title="Are you sure to delete this csr?"
              onConfirm={deleteCsr}
              okText="Yes"
              cancelText="No"
            >
              <Button
                icon={<DeleteOutlined />}
                type="primary"
                danger
                style={{ marginLeft: "10px" }}
              ></Button>
            </Popconfirm>
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    if (action === "EDIT") {
      setTimeout(() => {
        history.push(
          {
            pathname: "/addcsr",
            state: {
              status: csrRecord,
            },
          },
          2000
        );
      });
    }
  }, [csrRecord, action]);

  useEffect(() => {
    getCsrList();
  }, []);

  const deleteCsr = () => {
    let data = {
      csr_id: csrRecord.csr_id,
    };
    api.csrCrud
      .deleteCsr(data, token)
      .then((result) => {
        if (result.status === 1) {
          message.success(result.message);
          getCsrList();
        } else {
          message.error(result.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getCsrList = () => {
    api.csrCrud
      .getCsr(token)
      .then((result) => {
        console.log(result);
        if (result.status === 1) {
          setCsrData(result.data.reverse());
          setLoading(false);
        } else {
          setCsrData([]);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div>
      <div>
        <h2 style={{ display: "inline" }}>CSR</h2>
        <Link to="/addcsr">
          <Button
            type="primary"
            style={{ float: "right", marginBottom: "15px" }}
          >
            Add CSR
          </Button>
        </Link>
      </div>
      <Table
        columns={columns}
        dataSource={csrData}
        loading={loading}
        onRow={(record) => ({
          onClick: () => {
            setCsrRecord(record);
          },
        })}
      />
    </div>
  );
};

export default Csr;
