import React from "react";
import { Form, Input, Button } from "antd";

const AddResume = () => {
  return (
    <div>
      <h2 style={{marginLeft: "4%"}}>Add Resume</h2>
      <Form
        style={{ marginTop: "20px", marginLeft: "50px" }}
        labelCol={{ span: 3 }}
        wrapperCol={{ span: 10 }}
        size={"default"}
        labelAlign={"left"}
      >
        <Form.Item label="Name">
          <Input />
        </Form.Item>
        <Form.Item label="Email">
          <Input />
        </Form.Item>
        <Form.Item label="Mobile No.">
          <Input />
        </Form.Item>
        <Form.Item label="Resume">
       <Input type='file' />
        </Form.Item>
        <Form.Item label="Applied For">
        <Input />
        </Form.Item>
        <Form.Item>
          <Button type="primary" style={{marginLeft: "30%"}}>Add</Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default AddResume;
