import React, { useState } from 'react';
import { Form, Input, Button, message } from "antd";
import './login.css'
import api from '../../api';

const Login = (props) => {
    const [username, setUsername] = useState("")
    const [password, setPassword] = useState("")

    const handleAdminLogin = () => {
        let data = {
            admin_username: username,
            admin_password: password
        }
        console.log("LOGIN DATA ", data)
        api.auth.login(data).then(result => {
            console.log("LOGIN RESULT ", result);
            if (result.status === 1) {
                message.success(result.message);
                setTimeout(() => {
                    props.loggedIn(true);
                }, 3200);
                localStorage.setItem("token", JSON.stringify(result.token));
                localStorage.setItem("password", JSON.stringify(password));
            } else {
                message.error(result.message);
            }
        }).catch(error => {
            console.log(error)
        })
    }

    return (
        <div className="d-flex justify-content-center">
            <Form
                className=""
                style={{
                    marginTop: "10%",
                }}
                size={"default"}
                labelAlign={"left"}>
                <Form.Item >
                    <h1 style={{fontFamily: "Flink Medium"}} className="d-flex justify-content-center title-style">Cerebranium</h1>
                </Form.Item>
                <Form.Item label="Username" >
                    <Input onChange={(e) => setUsername(e.target.value)} />
                </Form.Item>
                <Form.Item label="Password">
                    <Input.Password onChange={(e) => setPassword(e.target.value)} />
                </Form.Item>
                <Form.Item >
                    <div className="d-flex justify-content-center btn-style">
                        <Button style={{
                            width: "50%"
                        }}
                            className=""
                            type="primary"
                            onClick={() => {
                                handleAdminLogin()
                            }}>
                            Login
                        </Button>
                    </div>
                </Form.Item>
            </Form>
        </div>);
}

export default Login;