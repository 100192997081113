import React, { useEffect, useState } from "react";
import {
  Form,
  Input,
  Button,
  Radio,
  message,
  DatePicker,
} from "antd";
import api from "../../api";
import { useHistory } from "react-router";
import FormData from "form-data";
import moment from "moment";

const { TextArea } = Input;

const AddEvent = () => {
  const [title, setTitle] = useState("");
  const [eventDate, setEventDate] = useState("");
  const [eventDesc, setEventDesc] = useState("");
  const [videoLink, setVideoLink] = useState("");
  const [status, setStatus] = useState("");
  const [file, setFile] = useState("");
  const [btnText, setBtnText] = useState("");
  const [btnUrl, setBtnUrl] = useState("");
  const [imgs, setImg] = useState("");

  const history = useHistory();
  const token = JSON.parse(localStorage.getItem("token"));
  useEffect(() => {
    if (history.location.state && history.location.state.status) {
      let data = history.location.state.status;
      setTitle(data.event_title);
      setEventDate(moment(data.event_date));
      setEventDesc(data.event_description);
      setVideoLink(data.video_link);
      setStatus(data.status);
      setImg(data.image_path);
      setFile(data.image_path);
      setBtnText(data.button_text);
      setBtnUrl(data.button_url);
    }
  }, []);

  const addOrEditEvent = async () => {
    if (
      !title ||
      !eventDate ||
      !eventDesc ||
      !status ||
      !file ||
      !btnText ||
      !btnUrl
    ) {
      alert("Please enter all required entries");
      return;
    }
    if (history.location.state && history.location.state.status) {
      let data = history.location.state.status;
      let updateData = new FormData();
      updateData.append("events_id", data.events_id);
      updateData.append("event_title", title);
      updateData.append("event_date", eventDate);
      updateData.append("event_description", eventDesc);
      updateData.append("status", status);
      updateData.append("video_link", videoLink);
      updateData.append("event_file", file);
      updateData.append("button_text", btnText);
      updateData.append("button_url", btnUrl);

      api.eventCrud
        .updateEvent(updateData, token)
        .then((result) => {
          if (result.status === 1) {
            message.success(result.message);
            setTimeout(() => {
              setTitle("");
              setEventDate("");
              setEventDesc("");
              setVideoLink("");
              setStatus("");
              setFile("");
              setBtnText("");
              setBtnUrl("");
              history.push("/event");
            }, 2000);
          } else {
            message.success(result.message);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      let data = new FormData();
      data.append("event_title", title);
      data.append("event_date", eventDate);
      data.append("event_description", eventDesc);
      data.append("status", status);
      data.append("video_link", videoLink);
      data.append("event_file", file);
      data.append("button_text", btnText);
      data.append("button_url", btnUrl);
      api.eventCrud
        .addEvent(data, token)
        .then((result) => {
          if (result.status === 1) {
            message.success(result.message);
            setTimeout(() => {
              setTitle("");
              setEventDate("");
              setEventDesc("");
              setVideoLink("");
              setStatus("");
              setFile("");
              setBtnText("");
              setBtnUrl("");
              history.push("/event");
            }, 2000);
          } else {
            message.error(result.message);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  return (
    <div>
      <h2>{history.location.state ? "Update Event" : "Add Event"}</h2>
      <Form
        style={{ marginTop: "20px", marginLeft: "50px" }}
        labelCol={{ span: 3 }}
        wrapperCol={{ span: 10 }}
        size={"default"}
        labelAlign={"left"}
      >
        <Form.Item label="Event Title" required={true}>
          <Input value={title} onChange={(e) => setTitle(e.target.value)} />
        </Form.Item>
        <Form.Item label="Event Date" required={true}>
          <DatePicker
            value={eventDate}
            format={"DD-MM-YYYY"}
            onChange={(value) => setEventDate(value)}
          />
        </Form.Item>
        <Form.Item label="Short Description" required={true}>
          <TextArea
            rows={4}
            value={eventDesc}
            maxLength="340"
            onChange={(e) => setEventDesc(e.target.value)}
          />
        </Form.Item>
        <div
          className=""
          style={{
            display: "flex",
            justifyContent: "end",
            width: "54%",
            marginTop: "-18px",
          }}
        >
          {eventDesc.length}/340
        </div>
        <Form.Item label="Button Text" required={true}>
          <Input
            value={btnText}
            maxLength={15}
            onChange={(e) => setBtnText(e.target.value)}
          />
        </Form.Item>
        <Form.Item label="Button URL" required={true}>
          <Input value={btnUrl} onChange={(e) => setBtnUrl(e.target.value)} />
        </Form.Item>
        <Form.Item label="Status" required={true}>
          <Radio.Group
            value={status}
            onChange={(e) => setStatus(e.target.value)}
          >
            <Radio value={"Active"}>Active</Radio>
            <Radio value={"Inactive"}>Inactive</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          label="Image"
          required={true}
          help="*Please upload image of size 246px x 272px"
        >
          <Input
            type="file"
            onChange={(e) => {
              setImg(
                URL.createObjectURL(e.target.files[0]),
                setFile(e.target.files[0])
              );
            }}
          />
          {imgs ? (
            <img src={imgs} height="90px" width="90px" alt="event_img" />
          ) : (
            ""
          )}
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" onClick={addOrEditEvent}>
            {history.location.state ? "Update Event" : "Add Event"}
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default AddEvent;
