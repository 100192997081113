import React, { useEffect, useState } from "react";
import { Form, Input, Button, Checkbox, Radio, message, Upload } from "antd";
import api from "../../api";
import { useHistory } from "react-router";
import FormData from "form-data";

const { TextArea } = Input;

const AddCsr = () => {
  const [institute, setinstitute] = useState("");
  const [eventName, seteventName] = useState("");
  const [eventDesc, setEventDesc] = useState("");
  const [status, setStatus] = useState("");
  const [file, setFile] = useState("");
  const [btnText, setBtnText] = useState("");
  const [btnUrl, setBtnUrl] = useState("");
  const [imgs, setImg] = useState("");

  const history = useHistory();
  const token = JSON.parse(localStorage.getItem("token"));

  useEffect(() => {
    console.log("HISTORY ", history);
    if (history.location.state && history.location.state.status) {
      let data = history.location.state.status;
      setinstitute(data.institute_name);
      seteventName(data.event_name);
      setEventDesc(data.event_description);
      setStatus(data.status);
      setImg(data.image_url);
      setFile(data.image_url);
      setBtnText(data.button_text);
      setBtnUrl(data.button_url);
    }
  }, []);

  const addOrEditCsr = async () => {
    if (
      !institute ||
      !eventName ||
      !eventDesc ||
      !status ||
      !file ||
      !btnText ||
      !btnUrl
    ) {
      alert("Please enter all required entries");
      return;
    }
    if (history.location.state && history.location.state.status) {
      let data = history.location.state.status;
      let updateData = new FormData();
      updateData.append("csr_id", data.csr_id);
      updateData.append("institute_name", institute);
      updateData.append("event_name", eventName);
      updateData.append("event_description", eventDesc);
      updateData.append("button_text", btnText);
      updateData.append("button_url", btnUrl);
      updateData.append("status", status);
      await updateData.append("csr_file", file);
      api.csrCrud
        .updateCsr(updateData, token)
        .then((result) => {
          if (result.status === 1) {
            message.success(result.message);
            setTimeout(() => {
              setinstitute("");
              seteventName("");
              setEventDesc("");
              setStatus("");
              setFile("");
              setBtnText("");
              setBtnUrl("");
              history.push("/csr");
            }, 2000);
          } else {
            message.success(result.message);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      let data = new FormData();
      data.append("institute_name", institute);
      data.append("event_name", eventName);
      data.append("event_description", eventDesc);
      data.append("status", status);
      data.append("csr_file", file);
      data.append("button_text", btnText);
      data.append("button_url", btnUrl);
      api.csrCrud
        .addCsr(data, token)
        .then((result) => {
          if (result.status === 1) {
            message.success(result.message);
            setTimeout(() => {
              setinstitute("");
              seteventName("");
              setEventDesc("");
              setStatus("");
              setFile("");
              setBtnText("");
              setBtnUrl("");
              history.push("/csr");
            }, 2000);
          } else {
            message.error(result.message);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  return (
    <div>
      <h2>{history.location.state ? "Update CSR" : "Add CSR"}</h2>
      <Form
        style={{ marginTop: "20px", marginLeft: "50px" }}
        labelCol={{ span: 3 }}
        wrapperCol={{ span: 10 }}
        size={"default"}
        labelAlign={"left"}
      >
        <Form.Item label="Institute Name" required={true}>
          <Input
            value={institute}
            onChange={(e) => setinstitute(e.target.value)}
          />
        </Form.Item>
        <Form.Item label="Event Name" required={true}>
          <Input
            value={eventName}
            onChange={(e) => seteventName(e.target.value)}
          />
        </Form.Item>
        <Form.Item label="Short Description" required={true}>
          <TextArea
            rows={4}
            value={eventDesc}
            maxLength="340"
            onChange={(e) => setEventDesc(e.target.value)}
          />
        </Form.Item>
        <div
          className=""
          style={{
            display: "flex",
            justifyContent: "end",
            width: "54%",
            marginTop: "-18px",
          }}
        >
          {eventDesc.length}/340
        </div>
        <Form.Item label="Button Text" required={true}>
          <Input
            value={btnText}
            maxLength={15}
            onChange={(e) => setBtnText(e.target.value)}
          />
        </Form.Item>
        <Form.Item label="Button URL" required={true}>
          <Input value={btnUrl} onChange={(e) => setBtnUrl(e.target.value)} />
        </Form.Item>
        <Form.Item label="Status" required={true}>
          <Radio.Group
            value={status}
            onChange={(e) => setStatus(e.target.value)}
          >
            <Radio value={"Active"}>Active</Radio>
            <Radio value={"Inactive"}>Inactive</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          label="Image"
          required={true}
          help="*Please upload image of size 246px x 272px"
        >
          <Input
            type="file"
            onChange={(e) => {
              setImg(
                URL.createObjectURL(e.target.files[0]),
                setFile(e.target.files[0])
              );
            }}
          />
          {imgs ? <img src={imgs} height="90px" width="90px" /> : ""}
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" onClick={addOrEditCsr}>
            {history.location.state ? "Update CSR" : "Add CSR"}
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default AddCsr;
