import React from "react";
import { Switch, Route, NavLink,Link } from "react-router-dom";
import "antd/dist/antd.css";
import { Layout, Menu, Button, message, Popconfirm } from "antd";
import { LogoutOutlined } from "@ant-design/icons";
import { useState } from "react";
import Team from "./components/Team/Team";
import AddTeam from "./components/Team/AddTeam";
import Career from "./components/Career/Career";
import AddCareer from "./components/Career/AddCareer";
import Resume from "./components/ReceivedResumes/Resumes";
import AddResume from "./components/ReceivedResumes/AddResume";
import Blogs from "./components/Blogs/Blogs";
import AddBlog from "./components/Blogs/AddBlogs";
import AddEnquiry from "./components/Enquiries/AddEnquiry";
import Enquiry from "./components/Enquiries/Enquiry";
import Csr from "./components/Csr/Csr";
import Login from "./components/Login/Login";
import AddCsr from "./components/Csr/AddCsr";
import Event from "./components/Event/Event";
import AddEvent from "./components/Event/AddEvent";
import ChangePassword from "./components/Login/PasswordChange";
import api from "./api";

const { Header, Content, Sider, Footer } = Layout;

function App() {
  const [collapsed, setCollapsed] = useState(false);
  const [login, setLogin] = useState(false);
  const token = JSON.parse(localStorage.getItem("token"));

  const loggedIn = () => {
    if (token) {
      setLogin(true);
    } else {
    }
  };
  if (login === false) {
    return <Login loggedIn={(data) => setLogin(data)} />;
  }
  return (
    <div className="App">
      <Layout style={{ minHeight: "100vh" }}>
        <Header className="header" style={{ padding: "0" }}>
          <span
            style={{
              color: "white",
              marginLeft: "30px",
              fontWeight: "bold",
              fontSize: "28px",
            }}
          >
            Cerebranium
          </span>
          <Link to='/changepassword'><span style={{
                color: "white",
                cursor: "pointer",
                fontSize: "1.1rem",
                marginTop: "4px",
                marginLeft: "63%"
              }}>Change Password</span></Link>
          <Popconfirm
            title="Are you sure you want to logout?"
            onConfirm={() => {
              api.auth.logout(JSON.parse(localStorage.getItem("token")));
              message.success("Logout successfully ");
              setTimeout(() => {
                localStorage.removeItem("token");
                localStorage.removeItem("password");
                setLogin(false);
              }, 2000);
            }}
            okText="Yes"
            cancelText="No"
          >
            <span
              style={{
                color: "white",
                marginLeft: "2%",
                cursor: "pointer",
                fontSize: "1.1rem",
                float: "right",
                marginRight: "30px"
              }}
              onClick={() => {}}
            >
              <Button
                className=""
                style={{
                  backgroundColor: "#001529",
                  borderColor: "#001529",
                  color: "white",
                  fontSize: "12px",
                }}
                icon={<LogoutOutlined />}
              ></Button>
              Logout
            </span>
          </Popconfirm>
        </Header>
        <Layout>
          <Sider trigger={null} collapsible collapsed={collapsed}>
            <div className="logo" />
            <Menu theme="dark" mode="inline" defaultSelectedKeys={["1"]}>
              <Menu.Item key="1">
                <NavLink to="/">Team</NavLink>
              </Menu.Item>
              <Menu.Item key="2">
                <NavLink to="/career">Careers</NavLink>
              </Menu.Item>
              <Menu.Item key="3">
                <NavLink to="/blogs">Blogs</NavLink>
              </Menu.Item>
              <Menu.Item key="4">
                <NavLink to="/csr">CSR</NavLink>
              </Menu.Item>
              <Menu.Item key="5">
                <NavLink to="/event">Events</NavLink>
              </Menu.Item>
              <Menu.Item key="6">
                <NavLink to="/contact">Contact Us</NavLink>
              </Menu.Item>
            </Menu>
          </Sider>
          <Layout style={{ padding: "0 24px 24px" }}>
            <Content
              className="site-layout-background"
              style={{
                padding: 24,
                margin: 0,
                minHeight: 280,
              }}
            >
              <Switch>
                <Route exact path="/" component={Team} />
                <Route exact path="/career" component={Career} />
                <Route exact path="/resume" component={Resume} />
                <Route exact path="/blogs" component={Blogs} />
                <Route exact path="/csr" component={Csr} />
                <Route exact path="/contact" component={Enquiry} />
                <Route exact path="/addblog" component={AddBlog} />
                <Route exact path="/addcareer" component={AddCareer} />
                <Route exact path="/addenquiry" component={AddEnquiry} />
                <Route exact path="/addresume" component={AddResume} />
                <Route exact path="/addteam" component={AddTeam} />
                <Route exact path="/addcsr" component={AddCsr} />
                <Route exact path="/event" component={Event} />
                <Route exact path="/addevent" component={AddEvent} />
                <Route exact path="/changepassword" component={ChangePassword} />
              </Switch>
            </Content>
            <Footer
              style={{ textAlign: "center", backgroundColor: "white" }}
            ></Footer>
          </Layout>
        </Layout>
      </Layout>
    </div>
  );
}
export default App;
