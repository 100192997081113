import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { Table, Button, Popconfirm, message } from "antd";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import api from "../../api";

const Career = () => {
  const [careerData, setCareerData] = useState([]);
  const [action, setAction] = useState("");
  const [careerRecord, setCareerRecord] = useState({});
  const [loading, setLoading] = useState(true);
  const history = useHistory();
  const token = JSON.parse(localStorage.getItem("token"));

  const columns = [
    {
      title: "Job Title",
      key: "title",
      dataIndex: "job_title",
    },
    {
      title: "Short Description",
      key: "description",
      dataIndex: "short_description",
      ellipsis: true,
    },
    {
      title: "Location",
      key: "location",
      dataIndex: "location",
    },
    {
      title: "Part Time/Full Time",
      key: "part_full",
      dataIndex: "part_full",
    },
    {
      title: "Status",
      key: "status",
      dataIndex: "status",
    },
    {
      title: "Actions",
      key: "action",
      dataIndex: "action",
      width: 120,
      render: () => {
        return (
          <div style={{ width: "100%" }}>
            <Button
              onClick={() => {
                setAction("EDIT");
              }}
              icon={<EditOutlined />}
              type="primary"
            ></Button>

            <Popconfirm
              title="Are you sure to delete this career?"
              onConfirm={deleteCareer}
              okText="Yes"
              cancelText="No"
            >
              <Button
                icon={<DeleteOutlined />}
                type="primary"
                danger
                style={{ marginLeft: "10px" }}
              ></Button>
            </Popconfirm>
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    if (action === "EDIT") {
      setTimeout(() => {
        history.push(
          {
            pathname: "/addcareer",
            state: {
              status: careerRecord,
            },
          },
          2000
        );
      });
    }
  }, [careerRecord, action]);

  useEffect(() => {
    getCareerList();
  }, []);

  const deleteCareer = () => {
    let data = {
      careers_id: careerRecord.careers_id,
    };
    api.careerCrud
      .deleteCareer(data, token)
      .then((result) => {
        if (result.status === 1) {
          message.success(result.message);
          getCareerList();
        } else {
          message.error(result.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getCareerList = () => {
    api.careerCrud
      .getCareer(token)
      .then((result) => {
        if (result.status === 1) {
          setCareerData(result.data.reverse());
          setLoading(false);
        } else {
          setCareerData([]);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div>
      <div>
        <h2 style={{ display: "inline-block" }}>Careers</h2>
        <Link to="/addcareer">
          <Button type="primary" style={{ float: "right", top: "8px" }}>
            Add Open Position
          </Button>
        </Link>
      </div>
      <Table
        columns={columns}
        dataSource={careerData}
        loading={loading}
        onRow={(record) => ({
          onClick: () => {
            setCareerRecord(record);
          },
        })}
      />
    </div>
  );
};

export default Career;
