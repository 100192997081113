import React, { useEffect, useState } from "react";
import { Form, Input, Button, Radio, message } from "antd";
import api from "../../api";
import { useHistory } from "react-router";
import FormData from "form-data";
const { TextArea } = Input;

const AddTeam = () => {
  const [name, setName] = useState("");
  const [designation, setDesignation] = useState("");
  const [keyQuote, setKeyQuote] = useState("");
  const [shortDesc, setShortDesc] = useState("");
  const [status, setStatus] = useState("");
  const [instaLink, setInstaLink] = useState("");
  const [fbLink, setFbLink] = useState("");
  const [linkedinLink, setlinkedinLink] = useState("");
  const [twitterLink, setTwitterLink] = useState("");
  const [file, setFile] = useState("");
  const [imgs, setImg] = useState("");
  const [sequence, setSequence] = useState();
  const history = useHistory();
  const token = JSON.parse(localStorage.getItem("token"));

  useEffect(() => {
    if (history.location.state && history.location.state.status) {
      let data = history.location.state.status;
      setName(data.name);
      setDesignation(data.designation);
      setKeyQuote(data.key_quote);
      setShortDesc(data.short_description);
      setStatus(data.status);
      setInstaLink(data.instagram_link);
      setFbLink(data.facebook_link);
      setlinkedinLink(data.linkedin_link);
      setTwitterLink(data.twitter_link);
      setImg(data.image_url);
      setFile(data.image_url);
      setSequence(data.sequence);
    }
  }, []);

  const addOrEditTeamMember = () => {
    if (!name || !designation || !file) {
      alert("Please enter all required entries");
      return;
    }

    if (history.location.state && history.location.state.status) {
      let data = history.location.state.status;
      let updatedData = new FormData();
      updatedData.append("team_members_id", data.team_members_id);
      updatedData.append("name", name);
      updatedData.append("key_quote", keyQuote);
      updatedData.append("designation", designation);
      updatedData.append("short_description", shortDesc);
      updatedData.append("status", status);
      updatedData.append("instagram_link", instaLink);
      updatedData.append("facebook_link", fbLink);
      updatedData.append("linkedin_link", linkedinLink);
      updatedData.append("twitter_link", twitterLink);
      updatedData.append("image_file", file);
      updatedData.append("sequence", sequence);
      console.log("UPATE DATA", updatedData);
      api.team_member_Crud
        .updateTeamMember(updatedData, token)
        .then((result) => {
          if (result.status === 1) {
            message.success(result.message);
            setTimeout(() => {
              setName("");
              setDesignation("");
              setKeyQuote("");
              setShortDesc("");
              setStatus("");
              setInstaLink("");
              setFbLink("");
              setlinkedinLink("");
              setTwitterLink("");
              setFile("");
              setSequence();
              history.push("/");
            }, 2000);
          } else {
            message.success(result.message);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      let data = new FormData();
      data.append("name", name);
      data.append("key_quote", keyQuote);
      data.append("designation", designation);
      data.append("short_description", shortDesc);
      data.append("status", status);
      data.append("instagram_link", instaLink);
      data.append("facebook_link", fbLink);
      data.append("linkedin_link", linkedinLink);
      data.append("twitter_link", twitterLink);
      data.append("image_file", file);
      data.append("sequence", sequence);
      api.team_member_Crud
        .addTeamMember(data, token)
        .then((result) => {
          if (result.status === 1) {
            message.success(result.message);
            setTimeout(() => {
              setName("");
              setDesignation("");
              setKeyQuote("");
              setShortDesc("");
              setStatus("");
              setInstaLink("");
              setFbLink("");
              setlinkedinLink("");
              setTwitterLink("");
              setFile("");
              setSequence();
              history.push("/");
            }, 2000);
          } else {
            message.error(result.message);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const validateMessages = {
    required: "Field is required!",
  };
  return (
    <div>
      <h2>
        {history.location.state ? "Update Team Member" : "Add Team Member"}
      </h2>
      <Form
        style={{ marginTop: "20px", marginLeft: "50px" }}
        labelCol={{ span: 3 }}
        wrapperCol={{ span: 10 }}
        size={"default"}
        labelAlign={"left"}
        validateMessages={validateMessages}
      >
        <Form.Item label="Name" required={true}>
          <Input value={name} onChange={(e) => setName(e.target.value)} />
        </Form.Item>
        <Form.Item label="Designation" required={true}>
          <Input
            value={designation}
            onChange={(e) => setDesignation(e.target.value)}
          />
        </Form.Item>
        <Form.Item label="Short Description">
          <TextArea
            rows={4}
            value={shortDesc}
            maxLength="340"
            onChange={(e) => setShortDesc(e.target.value)}
          />
        </Form.Item>
        <div
          className=""
          style={{
            display: "flex",
            justifyContent: "end",
            width: "54%",
            marginTop: "-18px",
          }}
        >
          {shortDesc.length}/340
        </div>
        <Form.Item label="Instagram Link">
          <Input
            value={instaLink}
            onChange={(e) => setInstaLink(e.target.value)}
          />
        </Form.Item>
        <Form.Item label="Facebook Link">
          <Input value={fbLink} onChange={(e) => setFbLink(e.target.value)} />
        </Form.Item>
        <Form.Item label="LinkedIn Link">
          <Input
            value={linkedinLink}
            onChange={(e) => setlinkedinLink(e.target.value)}
          />
        </Form.Item>
        <Form.Item label="Twitter Link">
          <Input
            value={twitterLink}
            onChange={(e) => setTwitterLink(e.target.value)}
          />
        </Form.Item>
        <Form.Item label="Sequence">
          <Input
            type="number"
            min={1}
            value={sequence}
            onChange={(e) => setSequence(e.target.value)}
          />
        </Form.Item>
        <Form.Item
          label="Image"
          help="*Please upload image of size 246px x 272px"
        >
          <Input
            type="file"
            onChange={(e) => {
              setImg(
                URL.createObjectURL(e.target.files[0]),
                setFile(e.target.files[0])
              );
            }}
          />
          {imgs ? (
            <img src={imgs} height="90px" width="90px" alt="team_img" />
          ) : (
            ""
          )}
        </Form.Item>
        <Form.Item label="Status">
          <Radio.Group
            value={status}
            onChange={(e) => setStatus(e.target.value)}
          >
            <Radio value={"Active"}>Active</Radio>
            <Radio value={"Inactive"}>Inactive</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            onClick={addOrEditTeamMember}
          >
            {history.location.state ? "Update Member" : "Add Member"}
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default AddTeam;
