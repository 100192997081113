import React, { useEffect, useState } from "react";
import { Table, Button, message, Popconfirm } from "antd";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { useHistory } from "react-router";
import api from "../../api";
import moment from "moment";

const Event = () => {
  const [eventList, setEventList] = useState([]);
  const [eventListRecord, setEventListRecord] = useState({});
  const [action, setAction] = useState("");
  const [loading, setLoading] = useState(true);
  const history = useHistory();
  const token = JSON.parse(localStorage.getItem("token"));

  useEffect(() => {
    getEventList();
  }, []);

  useEffect(() => {
    if (action === "EDIT") {
      setTimeout(() => {
        history.push(
          {
            pathname: "/addevent",
            state: {
              status: eventListRecord,
            },
          },
          2000
        );
      });
    }
  }, [eventListRecord, action]);

  const getEventList = () => {
    api.eventCrud
      .getEvent(token)
      .then((result) => {
        if (result.status === 1) {
          const newArr = result.data.sort((a, b) => {
            let dateA = new Date(
              moment(a.event_date).format("YYYY-MM-DD").toString()
            );
            let dateB = new Date(
              moment(b.event_date).format("YYYY-MM-DD").toString()
            );
            return dateB - dateA;
          });
          setEventList(newArr);
          setLoading(false);
        } else {
          setEventList([]);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const deleteEvent = () => {
    let data = {
      events_id: eventListRecord.events_id,
    };
    api.eventCrud
      .deleteEvent(data, token)
      .then((result) => {
        if (result.status === 1) {
          message.success(result.message);
          getEventList();
        } else {
          message.error(result.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const columns = [
    {
      title: "Event Title",
      key: "event_title",
      dataIndex: "event_title",
      width: 100,
    },
    {
      title: "Event Date",
      key: "event_date",
      dataIndex: "event_date",
      width: 100,
      render: (data) => {
        return moment(data).format("DD-MM-YYYY");
      },
    },
    {
      title: "Event Description",
      key: "event_description",
      dataIndex: "event_description",
      ellipsis: true,
      width: 300,
    },
    {
      title: "Status",
      key: "status",
      dataIndex: "status",
      width: 100,
    },
    {
      title: "Actions",
      key: "action",
      width: 100,
      dataIndex: "action",
      render: () => {
        return (
          <div style={{ width: "100%" }}>
            <Button
              onClick={() => {
                setAction("EDIT");
              }}
              icon={<EditOutlined />}
              type="primary"
            ></Button>

            <Popconfirm
              title="Are you sure to delete this event?"
              onConfirm={deleteEvent}
              okText="Yes"
              cancelText="No"
            >
              <Button
                icon={<DeleteOutlined />}
                type="primary"
                danger
                style={{ marginLeft: "10px" }}
              ></Button>
            </Popconfirm>
          </div>
        );
      },
    },
  ];

  return (
    <div>
      <div>
        <h2 style={{ display: "inline-block" }}>Events</h2>
        <Link to="/addevent">
          <Button type="primary" style={{ float: "right", top: "50%" }}>
            Add Event
          </Button>
        </Link>
      </div>
      <Table
        loading={loading}
        columns={columns}
        dataSource={eventList}
        onRow={(record) => ({
          onClick: () => {
            setEventListRecord(record);
          },
        })}
      />
    </div>
  );
};

export default Event;
