import React, { useState } from "react";
import { Input, Button, Form, message } from "antd";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import api from "../../api";

const ChangePassword = () => {
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const token = JSON.parse(localStorage.getItem("token"));
  const original = JSON.parse(localStorage.getItem("password"));
  const handleSubmit = (e) => {
    const data = {
      old_password: oldPassword,
      new_password: newPassword,
    };
    e.preventDefault();
    if (original === oldPassword) {
      if (confirmPassword === newPassword) {
        api.password
          .changePassword(data, token)
          .then((result) => {
            if (result.status === 1) {
              message.success("Password Change Successfully");
              setOldPassword("");
              setNewPassword("");
              setConfirmPassword("");
            } else {
              message.error("Password Change Failed");
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        message.error("Passwords Do not match");
      }
    } else {
      message.error("Old Password is incorrect");
    }
  };
  return (
    <>
      <h2>Change Password</h2>
      <Form
        style={{ marginTop: "20px", marginLeft: "50px" }}
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 10 }}
        size={"default"}
        labelAlign={"left"}
      >
        <Form.Item>
          <label>Old Password</label>
          <Input.Password
            value={oldPassword}
            onChange={(e) => setOldPassword(e.target.value)}
            style={{ width: "300px", marginLeft: "20px" }}
            iconRender={(visible) =>
              visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
            }
          />
        </Form.Item>

        <Form.Item>
          <label>New Password</label>
          <Input.Password
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            style={{ width: "300px", marginLeft: "20px" }}
            iconRender={(visible) =>
              visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
            }
          />
        </Form.Item>

        <Form.Item>
          <label>Confirm Password</label>
          <Input.Password
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            style={{ width: "300px", marginLeft: "20px" }}
            iconRender={(visible) =>
              visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
            }
          />
        </Form.Item>

        <Form.Item>
          <Button onClick={handleSubmit} type="primary">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default ChangePassword;
