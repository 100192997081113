import React, { useEffect, useState } from "react";
import { Table, Button, message, Popconfirm } from "antd";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { useHistory } from "react-router";
import api from "../../api";

const Team = () => {
  const [teamMemberList, setTeamMemberList] = useState([]);
  const [teamMemberRecord, setTeamMemberRecord] = useState({});
  const [action, setAction] = useState("");
  const [loading, setLoading] = useState(true);
  const history = useHistory();
  const token = JSON.parse(localStorage.getItem("token"));

  useEffect(() => {
    getTeamMemberList();
  }, []);

  useEffect(() => {
    if (action === "EDIT") {
      setTimeout(() => {
        history.push(
          {
            pathname: "/addteam",
            state: {
              status: teamMemberRecord,
            },
          },
          2000
        );
      });
    }
  }, [teamMemberRecord, action]);

  const getTeamMemberList = () => {
    api.team_member_Crud
      .getTeamMember(token)
      .then((result) => {
        console.log(result);
        if (result.status === 1) {
          setTeamMemberList(result.data);
          setLoading(false);
        } else {
          setTeamMemberList([]);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const deleteTeamMember = () => {
    let data = {
      team_members_id: teamMemberRecord.team_members_id,
    };
    api.team_member_Crud
      .deleteTeamMember(data, token)
      .then((result) => {
        if (result.status === 1) {
          message.success(result.message);
          getTeamMemberList();
        } else {
          message.error(result.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const columns = [
    {
      title: "Name",
      key: "name",
      width: 100,
      dataIndex: "name",
    },
    {
      title: "Designation",
      key: "designation",
      width: 100,
      dataIndex: "designation",
    },
    {
      title: "Short Description",
      key: "description",
      width: 100,
      dataIndex: "short_description",
      ellipsis: true,
    },
    {
      title: "Instagram Link",
      key: "instagram_link",
      width: 100,
      dataIndex: "instagram_link",
    },
    {
      title: "Facebook Link",
      key: "facebook_link",
      width: 100,
      dataIndex: "facebook_link",
    },
    {
      title: "LinkedIn Link",
      key: "linkedin_link",
      width: 100,
      dataIndex: "linkedin_link",
    },
    {
      title: "Twitter Link",
      key: "twitter_link",
      width: 100,
      dataIndex: "twitter_link",
    },
    {
      title: "Sequence",
      key: "sequence",
      dataIndex: "sequence",
    },

    {
      title: "Status",
      key: "status",
      width: 100,
      dataIndex: "status",
    },
    {
      title: "Actions",
      key: "action",
      width: 120,
      dataIndex: "action",
      render: () => {
        return (
          <div style={{ width: "100%" }}>
            <Button
              onClick={() => {
                setAction("EDIT");
              }}
              icon={<EditOutlined />}
              type="primary"
            ></Button>

            <Popconfirm
              title="Are you sure to delete this team member?"
              onConfirm={deleteTeamMember}
              okText="Yes"
              cancelText="No"
            >
              <Button
                icon={<DeleteOutlined />}
                type="primary"
                danger
                style={{ marginLeft: "10px" }}
              ></Button>
            </Popconfirm>
          </div>
        );
      },
    },
  ];
  return (
    <div>
      <div>
        <h2 style={{ display: "inline-block" }}>Team</h2>
        <Link to="/addteam">
          <Button type="primary" style={{ float: "right", top: "50%" }}>
            Add Team Member
          </Button>
        </Link>
      </div>
      <Table
        columns={columns}
        dataSource={teamMemberList}
        loading={loading}
        onRow={(record) => ({
          onClick: () => {
            setTeamMemberRecord(record);
          },
        })}
      />
    </div>
  );
};

export default Team;
